.week {
    font-size: 14px;
}

.day {
    font-size: 11px;
}

.pagination .active{
    background: #56839f !important;
}

.pagination{
    justify-content: flex-end;
}

.filter-item{
    cursor: pointer;
    flex: 0 0 auto;
    width: 12.5%;
	padding: 0 8px !important;
}

.filter-item-search{
    flex: 0 0 auto;
    width: 12.5%;
    padding-left: 8px;
    padding-right: 8px;
}

.filter-item-search .ant-select.ant-select-multiple.ant-select-disabled {
    cursor: pointer;
}

.note-container{
    width: 323px;
    padding: 12px;
    border-radius: 4px;
    background: #fff;
    position: relative;
}

.note-container .title{
    font-size: 16px;
    color: #333333;
    padding: 0px !important;
    line-height: 20px;
}

.note-container .title.blue{
    color: #0000FF !important;
}

.note-container .subtitle{
    font-size: 13px;
    color: #868383;
    padding: 0px !important;
    line-height: .4;
}

.note-container .url{
    font-size: 13px;
    color: #0000FF;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 16px;
    font-weight: 500;
}

.note-container .close-icon{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.comment-count{
    background-color: rgba(12, 54, 104, 1);
    font-size: 11px;
    position: absolute;
    height: 18px;
    width: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    font-weight: 700;
}

.filter-wrapper{
    position: relative !important;
}

.date-filter-container{
    width: 95px;
    padding: 10px;
    position: absolute;
    background-color: #fff;
    z-index: 2;
    transition: all .5s ease-in-out;
    max-height: 0px;
    overflow: hidden;
    padding: 0px
}

.date-filter-container.show{
    max-height: 500px;
    overflow-y: auto;
}

.date-filter-container .date-filter-content{
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
}

.date-filter-container .date-filter-content.selected{
    background-color: #cccaca;
}

.date-filter-container .date-filter-content:not(:last-child){
    margin-bottom: 3px;
}

.date-filter-container .date-filter-content .code,
.date-filter-container .date-filter-content .count{
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    width:50%;
}

.calender-schedule-container{
    overflow-x: auto;
}

.unauthorized{
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.unauthorized>p{
    font-size: 25px;
    color: #868383;
    line-height: .5;
}

.dragging-item{
    z-index: -10;
}

.dragging-item .dragging-active{
    visibility: visible !important;
}

.dragging-active{
    opacity: .8;
    visibility: hidden;
}

.task-box{
    cursor: pointer;
    font-size: 11px;
}

.loading-overlay{
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.3);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 20;
    left: 0;
}

.back-to-list-btn{
    border-radius: 5px;
    font-size: 16px;
    color: white;
    background-color: #56839f;
    border-color: #56839f;
    white-space: nowrap;
    vertical-align: middle;
    padding: 0.65rem 1.25rem;
    font-size: 1rem;
    line-height: 1.25;
    outline: none;
    border: none;
}

.back-to-list-btn:hover{
    background-color: #70a5ce;
    border-color: #70a5ce;
    color: #fff;
}

.custom-sticky-position{
    position: sticky;
    top: 0;
    z-index: 100;
}

.filter-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.full-screen-loader-container{
    position: relative;
}

.full-screen-loader-container .loader{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 101;
    background-color: rgba(0, 0, 0, 0.685);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-select-dropdown {
    width: 200px !important;
}

/* plan details modal */
.plan-details-modal table{
    width: 100%;
}

.plan-details-modal table thead > th {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #575962;
}

.plan-details-modal .ant-modal-close {
    display: none;
}

.plan-details-modal table tbody .task {
    height: 37px;
    border: #d8e2f0 1px solid;
    width: 70px;
    border-radius: 3px;
}

.plan-details-modal table tbody input {
    color: #575962;
    display: block;
    width: 100%;
    padding: 0.48rem 1.25rem;
    font-size: 1rem;
    line-height: 1.25;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    outline: none;
    width: 100px;
}

.plan-details-modal table tbody input:focus,
.plan-details-modal table tbody input:focus-visible,
.plan-details-modal table tbody input:hover {
    border-color: #231f20;
    color: #575962;
    outline: none;
}

/* Chrome, Safari, Edge, Opera */
.plan-details-modal table tbody input::-webkit-outer-spin-button,
.plan-details-modal table tbody input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.plan-details-modal table tbody input[type=number] {
  -moz-appearance: textfield;
}

.plan-details-modal table tbody .ant-picker {
    padding: 0px;
    border: none;
}

.plan-details-modal table tbody .ant-picker-focused {
    box-shadow: none;
}

.plan-details-modal table tbody .ant-picker-suffix {
    color: #575962;
    background-color: #f4f5f8;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    align-items: center;
    justify-content: center;
    color: #a4a2bb;
    height: 37px;
    margin-left: 0px;
    border: 1px solid #ccc;
    border-radius: 0px 0.25rem 0.25rem 0px;
}

.plan-details-modal table tbody .ant-picker-input input {
    border-radius: 0.25rem 0px 0px 0.25rem;
    border-right: none;
    width: 187px;
}

.plan-details-modal table tbody td {
    padding: 12px 8px;
    /* white-space: nowrap; */
}

.plan-details-modal table tbody td p {
    color: #575962;
    font-family: 'Roboto', sans-serif;
    padding-left: 12px;
    margin-bottom: 0px;
}

.plan-details-modal table tbody .task-name {
    display: flex;
    align-items: flex-start;
}

.plan-details-modal table thead th {
    padding: 12px 8px 12px 8px;
}

.plan-details-modal .modal-custom-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    margin-top: 8px;
    padding: 8px;
}

.plan-details-modal .modal-custom-footer button {
    background-color: #169BD5;
    border-color: #169BD5;
    border-radius: 4px;
    height: 37px;
    font-family: Roboto !important;
    font-size: 14px;
    line-height: 1.25;
    color: white;
}

.plan-details-modal table tbody .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 37px;
    border-color: #ccc !important;
}

.plan-details-modal table tbody .ant-select-focused .ant-select-selector {
    box-shadow: none;
}

.plan-details-modal table tbody .sortable-ghost {
    background-color: rgb(222, 244, 253); 
}

/* column search modal */
.column-search-modal {
    font-family: 'Roboto', sans-serif;
}

.column-search-modal .modal-custom-footer{
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.column-search-modal .modal-custom-footer button{
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: rgb(86, 131, 159);
    color: white;
    font-size: 16px;
    border-radius: 3px;
}

.column-search-modal .modal-custom-footer button.clear{
    background-color: #cf5858;
    color: white;
    margin-right: 10px;
}

.column-search-result{
    height: 43px;
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 3px;
    margin-bottom: 12px;
}

.column-search-result p {
    color: rgb(51, 51, 51);
    font-size: 13px;
    margin-bottom: 0px;
}

.column-search-result img{
    cursor: pointer;
}

.column-search-modal .column-search {
    height: 43px;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    color: rgb(51, 51, 51);
}
.column-search-modal .column-search .ant-select-selector {
    width: 100%;
    border: none;
    height: 100%;
    border: 1px solid rgb(215, 215, 215);
}

.column-search-modal .column-search .ant-modal-header{
    padding: 12px 24px;
}

/* .column-search-modal .column-search .ant-select-selection-placeholder {
    display: block !important;
}

.column-search-modal .column-search .ant-select-selection-item{
    display: none;
} */

.column-search-modal .ant-select-multiple .ant-select-selection-overflow {
    align-items: center;
    padding-left: 10px;
}

.column-search-modal .ant-select-multiple .ant-select-selection-item {
  display: none !important;
}

.column-search-modal .ant-select-multiple .ant-select-selection-overflow::before {
  visibility: visible;
  content: "Search";
  position: relative;
}

.column-search-modal .ant-select-multiple .ant-select-selector:focus-within .ant-select-selection-overflow::before{
    display: none;
}

.column-search-modal .ant-select-selection-placeholder{
    display: none;
}

.plan-details-modal .recalculate label{
    font-weight: 600;
}

.react-draggable {
    overflow: hidden;
    flex: 1;
}

.filter-item-search .input-group{
    cursor: pointer;
    flex-wrap: nowrap;
    background-color: #fff;
    border-radius: 5px;
}

.filter-item-search .input-group .search {
    box-sizing: border-box;
    padding: 0;
    padding: 4px 11px 4px;
    position: relative;
    display: inline-flex;
    align-items: center;
    border-radius: 5px 0 0 5px !important;
    overflow: hidden;
    transition: border 0.3s, box-shadow 0.3s;
    height: 32px;
    padding: 4px 11px;
    width: 80%;
}

.filter-item-search .ant-picker {
    height: 32px;
    border-radius: 5px 0 0 5px !important;
    border: none;
    background-color: transparent;
}
  
.filter-item-search .input-group .search p{
    margin: 0;
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
}
.filter-item-search .input-group button {
    padding: 2px 2px;
    height: 31px;
    background-color: transparent;
}

.filter-item-search button#filter {
    /* background: #fff; */
    border-radius: 0 5px 5px 0 !important;
    height: 32px;
    padding-right: 5px;
}

.filter-item-search i.fa-solid.fa-magnifying-glass {
    color: #56839f;
}

.filter-item-search .filter-applied {
    background: #D7E3EA !important;
}